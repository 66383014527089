import {bootstrap, IBootstrap, inject, LogLevel} from '@injex/core';
import {RenderInjexProvider} from '@injex/react-plugin';
import {Injex} from '@injex/webpack';
import React from 'react';
import Root from './modules/root/view/Root';
import {SessionManager} from './modules/session/managers/sessionManager.mdl';

@bootstrap()
export class Bootstrap implements IBootstrap {
    @inject() private renderInjexProvider: RenderInjexProvider;
    @inject() private sessionManager: SessionManager;
    @inject() private $injex: Injex;

    public run() {
        this.$injex.logger.setNamespace('Publishers');
        this.$injex.logger.setLogLevel(LogLevel.Info);

        this.sessionManager.initialize();

        this.renderInjexProvider(<Root />);
    }
}