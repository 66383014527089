import {alias, AliasMap, define, init, injectAlias, singleton} from '@injex/core';
import {computed, makeObservable, observable} from 'mobx';
import {TimeRangeType} from '../common/enums';
import ITimeRange, {TimeRangeResolve} from '../interfaces/ITimeRange';
import timezones from 'timezones-list';
import ITimezoneMetadata from '../interfaces/ITimezoneMetadata';
import Hook from '../../../stdlib/Hook';
import IDisposable from '../../../common/interfaces/IDisposable';
import dayjs from 'dayjs';
import {getTimezonOffsetFromUTC} from '../../../stdlib/utils';

const TIMEZONES_INDEX = timezones.reduce((dict, meta) => {
    dict[meta.tzCode] = meta;
    return dict;
}, []);

export type TimeManagerHooks = {
    timeChange: Hook;
};

@define()
@singleton()
@alias('Disposable')
export class TimeManager implements IDisposable {
    @injectAlias('TimeRange', 'rangeType') private timeRanges: AliasMap<TimeRangeType, ITimeRange>;
    public orderedTimeRanges: ITimeRange[];

    @observable public currentTimeRangeType: TimeRangeType;
    @observable public currentTimeZone: string;

    public hooks: TimeManagerHooks;
    private _changeTimeout: number;

    constructor() {
        makeObservable(this);
        this._reset();
        this.hooks = {
            timeChange: new Hook()
        };
    }

    private _reset() {
        this.currentTimeRangeType = TimeRangeType.Today;
        this.currentTimeZone = 'Europe/London';
    }

    @init()
    protected initialize() {
        this.orderedTimeRanges = Object
            .values(this.timeRanges)
            .sort((range1, range2) => range1.order - range2.order);
    }

    public setTimeRange(type: TimeRangeType) {
        this.currentTimeRangeType = type;
        this._triggerChange();
    }

    public setTimezone(tzCode: string) {
        this.currentTimeZone = tzCode;
        this._triggerChange();
    }

    /**
     * Use async change trigger to prevent multiple events when
     * changing the timezone and the time range synchronously.
     */
    private _triggerChange() {
        window.clearTimeout(this._changeTimeout);

        this._changeTimeout = window.setTimeout(() => {
            this.hooks.timeChange.call();
        });
    }

    public getTimeRangeByType(type: TimeRangeType): ITimeRange {
        return this.timeRanges[type];
    }

    public getTimezoneMetadataByCode(tzCode: string): ITimezoneMetadata {
        return TIMEZONES_INDEX[tzCode];
    }

    public get timezones(): ITimezoneMetadata[] {
        return timezones;
    }

    @computed public get currentTimeRange(): ITimeRange {
        return this.timeRanges[this.currentTimeRangeType];
    }

    @computed public get currentTimeRangeResolved(): TimeRangeResolve {
        return this.currentTimeRange.resolve();
    }

    @computed public get currentTimezoneMetadata(): ITimezoneMetadata {
        return TIMEZONES_INDEX[this.currentTimeZone];
    }

    @computed public get timezoneOffset(): number {
        return getTimezonOffsetFromUTC(this.currentTimezoneMetadata.utc);
    }

    public timeRangeDiff(unit = 'days'): number {
        const [from, to] = this.currentTimeRangeResolved;
        return Math.abs(Math.round(dayjs(from * 1000).diff(to * 1000, unit as any, true)));
    }

    public withTimezoneOffset(time: number): number {
        return time + 60 * 60 * this.timezoneOffset;
    }

    public dispose(): void {
        this._reset();
    }
}