import {init, inject} from '@injex/core';
import {AxiosPromise, AxiosRequestConfig} from 'axios';
import IEnv from '../common/interfaces/IEnv';
import {SessionManager} from '../modules/session/managers/sessionManager.mdl';
import RequestAdapter from './RequestAdapter';

export type CrudItemParams<T> = { [J in keyof Partial<T>]: any };

export type GetByIdModel<T> = {
    fields?: CrudItemParams<T>;
    populate?: any[];
    lean?: boolean;
};

export type GetAllModel<T> = {
    page?: number;
    pageSize?: number;
    sort?: CrudItemParams<T>;
    filter?: CrudItemParams<T>;
    fields?: CrudItemParams<T>;
    populate?: any[];
    lean?: boolean;
};

export type RequestContext = {
    accessToken?: string;
    accounts?: string[];
};

export interface IRequestContextResolver {
    getContext(): RequestContext;
}

export default abstract class EntityRequestAdapter<T = any> extends RequestAdapter {
    @inject() private env: IEnv;
    @inject() private sessionManager: SessionManager;
    protected abstract basePathName: string;

    @init()
    protected initialize() {
        this.createHTTPClient(this.env.api);
    }

    public async getAllActive(model: GetAllModel<T>): Promise<{ results: T[]; count: number; }> {
        model.filter = model.filter || {} as any;
        model.filter['isActive'] = true;
        return this.getAll(model);
    }

    public async getAll(model: GetAllModel<T>): Promise<{ results: T[]; count: number; }> {
        const response = await this.requestWithContext<{ results: T[]; count: number; }>({
            method: 'get',
            url: this.makeAPIPath('/'),
            params: model
        });

        return response.data;
    }

    public async getById(id: string, model: GetByIdModel<T>): Promise<T> {
        const response = await this.requestWithContext<T>({
            method: 'get',
            url: this.makeAPIPath(`/${id}`),
            params: model
        });

        return response.data;
    }

    public async create(model: Partial<T>): Promise<T> {
        const response = await this.requestWithContext<T>({
            method: 'post',
            url: this.makeAPIPath('/'),
            data: model
        });

        return response.data;
    }

    public async updateById(id: string, model: Partial<T>): Promise<T> {
        const response = await this.requestWithContext<T>({
            method: 'patch',
            url: this.makeAPIPath(`/${id}`),
            data: model
        });

        return response.data;
    }

    public async deleteById(id: string): Promise<void> {
        const response = await this.requestWithContext<void>({
            method: 'delete',
            url: this.makeAPIPath(`/${id}`)
        });

        return response.data;
    }

    public async activeById(id: string, isActive: boolean): Promise<T> {
        const response = await this.requestWithContext<T>({
            method: 'patch',
            url: this.makeAPIPath(`/${id}/active`),
            data: {isActive}
        });

        return response.data;
    }

    protected requestWithContext<K>(config: AxiosRequestConfig): AxiosPromise<K> {
        const {accounts = [], accessToken = ''} = this.sessionManager.getRequestContext();
        config.headers = {
            'Authorization': `Bearer ${accessToken}`,
            'X-Accounts': accounts.join(','),
            ...config.headers
        };

        return super.request<K>(config);
    }

    protected makeAPIPath(path: string): string {
        return `/api/${this.basePathName}${path}`;
    }
}
