import {alias, define, inject, injectAlias, singleton} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import IDisposable from '../../../common/interfaces/IDisposable';
import {RequestContext} from '../../../stdlib/EntityRequestAdapter';
import Hook from '../../../stdlib/Hook';
import IViewAsUser from '../../account/interfaces/IViewAsUser';
import {SessionAPI} from '../api/session.mdl';
import {ViewAsAPI} from '../api/viewas.mdl';
import {AuthStatus} from '../common/enums';
import IAccountsSDK, {ISession} from '../interfaces/IAccountsSDK';

export type SessionHooks = {
    loginSuccess: Hook;
    statusChange: Hook<[status: AuthStatus]>;
};

const VIEW_AS_ROLE_NAMES = ['VIEW_AS', 'VIEW_AS_STRICT'];

@define()
@singleton()
@alias('Disposable')
export class SessionManager implements IDisposable {
    @inject() private sessionAPI: SessionAPI;
    @inject() private viewasAPI: ViewAsAPI;
    @injectAlias('Disposable') private disposables: IDisposable[];

    public hooks: SessionHooks;
    public accountsSdk: IAccountsSDK;
    public canViewAs: boolean;
    @observable public status: AuthStatus;
    @observable public loginError: boolean;
    @observable public initialAuthorization: boolean;

    @observable public viewAsUsers: IViewAsUser[];
    @observable public isLoadingViewAsUsers: boolean;
    @observable public didLoadViewAsUsers: boolean;

    constructor() {
        makeObservable(this);

        this.status = AuthStatus.Authorizing;
        this.initialAuthorization = true;
        this.canViewAs = false;
        this.viewAsUsers = [];
        this.isLoadingViewAsUsers = false;
        this.didLoadViewAsUsers = false;

        this.hooks = {
            loginSuccess: new Hook(),
            statusChange: new Hook(),
        };
    }

    public async loadViewAsUsers(useForce?: boolean) {
        if (this.isLoadingViewAsUsers || (this.didLoadViewAsUsers && !useForce)) {
            return;
        }

        this.isLoadingViewAsUsers = true;
        const viewAsUsers = await this.viewasAPI.getViewAsUsers();
        this.viewAsUsers = observable.array(viewAsUsers, {deep: false});
        this.didLoadViewAsUsers = true;
        this.isLoadingViewAsUsers = false;
    }

    public selectViewAsUser(email: string) {
        this.accountsSdk.auth.viewAs(email);
    }

    public stopViewAs() {
        if (this.isViewAsSession) {
            window.sessionStorage.removeItem('viewAsData');
            window.location.href = '/home';
        }
    }

    public getRequestContext(): RequestContext {
        if (this.isAuthenticated) {
            return {
                accessToken: this.accountsSdk.auth.session.accessToken,
                accounts: this.accountsSdk.auth.selectedAccounts
            };
        }

        return {};
    }

    public get isAuthenticated(): boolean {
        return this.accountsSdk?.auth.isAuthenticated;
    }

    public get isViewAsSession(): boolean {
        return this.accountsSdk?.auth.isViewAsSession;
    }

    public get session() {
        if (!this.isAuthenticated) {
            return null;
        }

        return this.accountsSdk.auth.session;
    }

    public get user() {
        if (!this.isAuthenticated) {
            return null;
        }

        return this.session.user;
    }

    public get fullName(): string {
        if (!this.isAuthenticated) {
            return '';
        }

        return `${this.user.firstName} ${this.user.lastName}`;
    }

    public get email(): string {
        if (!this.isAuthenticated) {
            return '';
        }

        return this.user.email;
    }

    public get org(): string {
        if (!this.isAuthenticated) {
            return '';
        }

        return this.user.organization.name;
    }

    public async initialize() {
        this.accountsSdk = await window['VidazooAccountsSDK'].create({
            scope: 'publishers'
        });

        this.accountsSdk.auth.hooks.sessionChanged.tap(this._onSessionChanged, null, this);

        this._setStatus(AuthStatus.Authorizing);

        this.accountsSdk.auth.authorize(true);
    }

    public login(email: string, password: string) {
        this.initialAuthorization = false;
        this.loginError = false;
        this._setStatus(AuthStatus.Authorizing);
        this.accountsSdk.auth.login(email, password);
    }

    public logout() {
        this.accountsSdk.auth.logout(true);
    }

    public sendPasswordRecoveryLink(email: string, recaptchaToken: string) {
        this.sessionAPI.forgotPassword(email, recaptchaToken);
    }

    private async _onSessionChanged(error: Error, session: ISession) {
        if (session) {
            await this.hooks.loginSuccess.call();

            const scope = session.user.scopes.find((scope) => scope.scope === 'publishers');

            if (scope.roles.find((role) => VIEW_AS_ROLE_NAMES.includes(role.name))) {
                this.canViewAs = true;
            }

            this._setStatus(AuthStatus.LoggedIn);
        } else {
            this.loginError = error !== null;
            this._setStatus(AuthStatus.LoggedOut);
            this.disposables.forEach((disposable) => disposable.dispose());
        }
    }

    private _setStatus(status: AuthStatus) {
        if (this.status === status) {
            return;
        }

        this.status = status;
        this.hooks.statusChange.call(this.status);
    }

    public dispose() {
        this.initialAuthorization = true;
        this.canViewAs = false;
        this.viewAsUsers = [];
        this.isLoadingViewAsUsers = false;
        this.didLoadViewAsUsers = false;
    }
}