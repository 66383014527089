import {alias, define, singleton} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import {TimeRangeType} from '../common/enums';
import ITimeRange, {TimeRangeResolve, TimeRangeResolveCompare} from '../interfaces/ITimeRange';

@define()
@singleton()
@alias('TimeRange')
export class CustomTimeRange implements ITimeRange {
    public readonly rangeType: TimeRangeType = TimeRangeType.Custom;
    public readonly order: number = 110;
    public readonly displayName: string = 'Custom';

    @observable private _from: number;
    @observable private _to: number;
    @observable private _label: string;

    constructor() {
        makeObservable(this);
    }

    public resolve(): TimeRangeResolve {
        return [
            this._from,
            this._to,
            this._label
        ];
    }

    public setDates(from: number, to: number, label: string) {
        this._from = from;
        this._to = to;
        this._label = label;
    }

    public resolveCompare(): TimeRangeResolveCompare {
        return [0, 0];
    }
}