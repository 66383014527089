"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = exports.LogLevel = void 0;
var tslib_1 = require("tslib");
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["Error"] = 0] = "Error";
    LogLevel[LogLevel["Warn"] = 1] = "Warn";
    LogLevel[LogLevel["Info"] = 2] = "Info";
    LogLevel[LogLevel["Debug"] = 3] = "Debug";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
/**
 * Decorates a logger method, once it get's called,
 * check if it allowed and invoke it.
 *
 * @param level - The highest log level to allow
 */
function filterLogLevel(level) {
    return function (target, name, propertyDescriptor) {
        var originalFn = propertyDescriptor.value;
        propertyDescriptor.value = function () {
            if (this.logLevel >= level) {
                originalFn.apply(this, arguments);
            }
        };
    };
}
var Logger = /** @class */ (function () {
    function Logger(logLevel, namespace) {
        this.logLevel = logLevel;
        this.namespace = namespace;
    }
    Logger.prototype.setLogLevel = function (logLevel) {
        this.logLevel = logLevel;
        return this;
    };
    Logger.prototype.setNamespace = function (namespace) {
        this.namespace = namespace;
        return this;
    };
    Logger.prototype.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.invokeLogMethod("info", args);
    };
    Logger.prototype.debug = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.invokeLogMethod("debug", args);
    };
    Logger.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.invokeLogMethod("warn", args);
    };
    Logger.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.invokeLogMethod("error", args);
    };
    Logger.prototype.invokeLogMethod = function (method, args) {
        args = tslib_1.__spreadArrays([
            (new Date()).toLocaleTimeString() + " [" + method.toUpperCase() + "] " + this.namespace + ":"
        ], Array.from(args));
        console[method].apply(console, args);
    };
    tslib_1.__decorate([
        filterLogLevel(LogLevel.Info),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], Logger.prototype, "info", null);
    tslib_1.__decorate([
        filterLogLevel(LogLevel.Debug),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], Logger.prototype, "debug", null);
    tslib_1.__decorate([
        filterLogLevel(LogLevel.Warn),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], Logger.prototype, "warn", null);
    tslib_1.__decorate([
        filterLogLevel(LogLevel.Error),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], Logger.prototype, "error", null);
    return Logger;
}());
exports.Logger = Logger;
