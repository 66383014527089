"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useModuleFactory = void 0;
var React = require("react");
var useInjex_1 = require("./useInjex");
/**
 * Create a module<T> instance using a factory method
 *
 * @returns Instance of module<T>
 */
function useModuleFactory(moduleName, args, memoDependencies) {
    if (args === void 0) { args = []; }
    if (memoDependencies === void 0) { memoDependencies = []; }
    var inject = useInjex_1.useInjex()[0];
    var factoryMethod = inject(moduleName);
    return React.useMemo(function () { return factoryMethod.apply(void 0, args); }, memoDependencies);
}
exports.useModuleFactory = useModuleFactory;
