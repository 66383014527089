import {define, singleton} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import Hook from '../../../stdlib/Hook';

export type AppManagerHooks = {
    refreshRequest: Hook;
};

@define()
@singleton()
export class AppManager {
    @observable public isRefreshing: boolean;

    public hooks: AppManagerHooks;

    constructor() {
        makeObservable(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.isRefreshing = false;
        this.hooks = {
            refreshRequest: new Hook()
        };
    }

    public async onRefresh() {
        this.isRefreshing = true;
        await this.hooks.refreshRequest.call();
        this.isRefreshing = false;
    }
}