"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lazy = void 0;
var metadataHandlers_1 = require("../metadataHandlers");
function lazy() {
    return function (targetConstructor) {
        metadataHandlers_1.default.setMetadata(targetConstructor.prototype, "lazy", true);
        metadataHandlers_1.default.setMetadata(targetConstructor.prototype, "singleton", true);
    };
}
exports.lazy = lazy;
