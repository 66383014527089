"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Injex = exports.errors = exports.Logger = exports.LogLevel = exports.lazy = exports.alias = exports.bootstrap = exports.init = exports.singleton = exports.injectParam = exports.injectAlias = exports.injectFactory = exports.inject = exports.define = void 0;
var tslib_1 = require("tslib");
var define_1 = require("./decorators/define");
Object.defineProperty(exports, "define", { enumerable: true, get: function () { return define_1.define; } });
var inject_1 = require("./decorators/inject");
Object.defineProperty(exports, "inject", { enumerable: true, get: function () { return inject_1.inject; } });
var injectFactory_1 = require("./decorators/injectFactory");
Object.defineProperty(exports, "injectFactory", { enumerable: true, get: function () { return injectFactory_1.injectFactory; } });
var injectAlias_1 = require("./decorators/injectAlias");
Object.defineProperty(exports, "injectAlias", { enumerable: true, get: function () { return injectAlias_1.injectAlias; } });
var injectParam_1 = require("./decorators/injectParam");
Object.defineProperty(exports, "injectParam", { enumerable: true, get: function () { return injectParam_1.injectParam; } });
var singleton_1 = require("./decorators/singleton");
Object.defineProperty(exports, "singleton", { enumerable: true, get: function () { return singleton_1.singleton; } });
var init_1 = require("./decorators/init");
Object.defineProperty(exports, "init", { enumerable: true, get: function () { return init_1.init; } });
var bootstrap_1 = require("./decorators/bootstrap");
Object.defineProperty(exports, "bootstrap", { enumerable: true, get: function () { return bootstrap_1.bootstrap; } });
var alias_1 = require("./decorators/alias");
Object.defineProperty(exports, "alias", { enumerable: true, get: function () { return alias_1.alias; } });
var lazy_1 = require("./decorators/lazy");
Object.defineProperty(exports, "lazy", { enumerable: true, get: function () { return lazy_1.lazy; } });
var stdlib_1 = require("@injex/stdlib");
Object.defineProperty(exports, "LogLevel", { enumerable: true, get: function () { return stdlib_1.LogLevel; } });
Object.defineProperty(exports, "Logger", { enumerable: true, get: function () { return stdlib_1.Logger; } });
exports.errors = require("./errors");
tslib_1.__exportStar(require("./interfaces"), exports);
var injex_1 = require("./injex");
Object.defineProperty(exports, "Injex", { enumerable: true, get: function () { return injex_1.default; } });
