import {define} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import {guid} from '../../../stdlib/utils';
import IActivityInsightCardEntry from '../interfaces/IActivityInsightCardEntry';

export type ActivityInsightCardFieldData = {
    field: string;
    data: IActivityInsightCardEntry[];
    didLoad: boolean;
    isLoading: boolean;
};

@define()
export class ActivityInsightCard {
    public readonly id: string;
    public group: string;
    public title: string;
    public viewable: boolean;

    @observable public fieldsData: { [index: string]: ActivityInsightCardFieldData; };

    public get didLoad(): boolean {
        for (const field in this.fieldsData) {
            if (this.fieldsData[field].didLoad) {
                return true;
            }
        }

        return false;
    }

    constructor(group: string, title: string) {
        makeObservable(this);
        this.id = guid();
        this.group = group;
        this.title = title;
        this.fieldsData = {};
    }

    public setViewability(viewable: boolean) {
        this.viewable = viewable;
    }

    public setFieldData(field: string, data: IActivityInsightCardEntry[]) {
        this.getOrCreateFieldData(field).data = data;
    }

    public getDataField(field: string) {
        return this.fieldsData[field];
    }

    public getOrCreateFieldData(field: string) {
        if (this.fieldsData[field]) {
            return this.fieldsData[field];
        }

        const fieldData: ActivityInsightCardFieldData = {
            field,
            data: [],
            didLoad: false,
            isLoading: false,
        };

        this.fieldsData[field] = fieldData;

        return this.fieldsData[field];
    }

    public clearData() {
        for (const field in this.fieldsData) {
            this.fieldsData[field].didLoad = false;
            this.fieldsData[field].data = [];
        }
    }
}