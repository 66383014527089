"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = void 0;
var metadataHandlers_1 = require("../metadataHandlers");
function init() {
    return function (targetPrototype, methodName) {
        metadataHandlers_1.default.setMetadata(targetPrototype, "initMethod", methodName);
    };
}
exports.init = init;
