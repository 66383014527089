"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inject = void 0;
var metadataHandlers_1 = require("../metadataHandlers");
function inject(dependencyNameOrType) {
    return function (targetPrototype, dependency) {
        metadataHandlers_1.default.pushMetadata(targetPrototype, "dependencies", {
            label: dependency,
            value: dependencyNameOrType || dependency
        });
    };
}
exports.inject = inject;
