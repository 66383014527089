"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnvPlugin = void 0;
var tslib_1 = require("tslib");
var createConfig_1 = require("./createConfig");
var EnvPlugin = /** @class */ (function () {
    function EnvPlugin(config) {
        this.config = createConfig_1.default(config);
    }
    EnvPlugin.prototype.apply = function (container) {
        var current = this.config.environments[this.config.current] || {};
        var defaults = this.config.defaults || {};
        var environment = tslib_1.__assign(tslib_1.__assign({}, defaults), current);
        container.addObject(environment, this.config.name);
    };
    return EnvPlugin;
}());
exports.EnvPlugin = EnvPlugin;
