"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hook = void 0;
var tslib_1 = require("tslib");
var Hook = /** @class */ (function () {
    function Hook() {
        this._callbacks = [];
    }
    Hook.prototype.pipe = function (hook) {
        this.tap(hook.call, null, hook);
    };
    Hook.prototype.unpipe = function (hook) {
        this.untap(hook.call);
    };
    Hook.prototype.tap = function (callback, catchFn, scope) {
        this._callbacks.push({
            async: false,
            callback: callback,
            catchFn: catchFn,
            scope: scope,
        });
    };
    Hook.prototype.tapAsync = function (callback, catchFn, scope) {
        this._callbacks.push({
            async: true,
            callback: callback,
            catchFn: catchFn,
            scope: scope,
        });
    };
    Hook.prototype.untap = function (callbackToRemove) {
        this._callbacks = this._callbacks.filter(function (_a) {
            var callback = _a.callback;
            return callbackToRemove !== callback;
        });
    };
    Hook.prototype.call = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var callbackArgs, i, len, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        i = 0, len = this._callbacks.length;
                        _a.label = 1;
                    case 1:
                        if (!(i < len)) return [3 /*break*/, 8];
                        callbackArgs = this._callbacks[i];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 7]);
                        if (!callbackArgs.async) return [3 /*break*/, 4];
                        return [4 /*yield*/, callbackArgs.callback.apply(callbackArgs.scope, args)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        callbackArgs.callback.apply(callbackArgs.scope, args);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        if (callbackArgs.catchFn) {
                            callbackArgs.catchFn(e_1);
                        }
                        else {
                            throw e_1;
                        }
                        return [3 /*break*/, 7];
                    case 7:
                        i++;
                        return [3 /*break*/, 1];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    return Hook;
}());
exports.Hook = Hook;
