"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMetadataHandlers = void 0;
require("reflect-metadata");
function createMetadataHandlers(metadataKey) {
    function ensureMetadata(target) {
        if (!hasMetadata(target)) {
            Reflect.defineMetadata(metadataKey, {}, target);
        }
        return getMetadata(target);
    }
    function setMetadata(target, key, value) {
        var metadata = ensureMetadata(target);
        metadata[key] = value;
    }
    function getMetadata(target) {
        return Reflect.getOwnMetadata(metadataKey, target);
    }
    function hasMetadata(target) {
        return Reflect.hasOwnMetadata(metadataKey, target);
    }
    function pushMetadata(target, key, value) {
        var metadata = ensureMetadata(target);
        if (!metadata[key]) {
            setMetadata(target, key, []);
        }
        metadata[key].push(value);
    }
    function forEachProtoMetadata(target, callback) {
        var __proto__ = target === null || target === void 0 ? void 0 : target.__proto__;
        while (__proto__) {
            var meta = getMetadata(__proto__);
            if (meta) {
                callback(__proto__, meta);
            }
            __proto__ = __proto__.__proto__;
        }
    }
    return {
        ensureMetadata: ensureMetadata,
        setMetadata: setMetadata,
        getMetadata: getMetadata,
        hasMetadata: hasMetadata,
        pushMetadata: pushMetadata,
        forEachProtoMetadata: forEachProtoMetadata,
    };
}
exports.createMetadataHandlers = createMetadataHandlers;
