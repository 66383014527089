"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var stdlib_1 = require("@injex/stdlib");
var constants_1 = require("./constants");
var errors_1 = require("./errors");
var metadataHandlers_1 = require("./metadataHandlers");
var InjexContainer = /** @class */ (function () {
    function InjexContainer(config) {
        this.config = this.createConfig(config);
        this._logger = new stdlib_1.Logger(this.config.logLevel, this.config.logNamespace);
        this._moduleRegistry = new Map();
        this._modules = new Map();
        this._aliases = new Map();
        this.addObject(this, "$injex");
        this._createHooks();
        this._logger.debug("Container created with config", this.config);
    }
    Object.defineProperty(InjexContainer.prototype, "logger", {
        get: function () {
            return this._logger;
        },
        enumerable: false,
        configurable: true
    });
    InjexContainer.create = function (config) {
        throw new Error("Static method create not implemented.");
    };
    InjexContainer.prototype.bootstrap = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var bootstrapModule, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._initPlugins()];
                    case 1:
                        _a.sent();
                        this.hooks.beforeRegistration.call();
                        return [4 /*yield*/, this.loadContainerFiles()];
                    case 2:
                        _a.sent();
                        this.hooks.afterRegistration.call();
                        this._createModules();
                        bootstrapModule = this.get(constants_1.bootstrapSymbol);
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        return [4 /*yield*/, this._initializeModules()];
                    case 4:
                        _a.sent();
                        this.hooks.bootstrapRun.call();
                        return [4 /*yield*/, (bootstrapModule === null || bootstrapModule === void 0 ? void 0 : bootstrapModule.run())];
                    case 5:
                        _a.sent();
                        this.hooks.bootstrapComplete.call();
                        return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        this.hooks.bootstrapError.call(e_1);
                        if (bootstrapModule === null || bootstrapModule === void 0 ? void 0 : bootstrapModule.didCatch) {
                            bootstrapModule === null || bootstrapModule === void 0 ? void 0 : bootstrapModule.didCatch(e_1);
                        }
                        else {
                            throw e_1;
                        }
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/, this];
                }
            });
        });
    };
    InjexContainer.prototype.registerModuleExports = function (moduleExports) {
        for (var _i = 0, _a = Object.getOwnPropertyNames(moduleExports); _i < _a.length; _i++) {
            var key = _a[_i];
            this._register(moduleExports[key]);
        }
    };
    InjexContainer.prototype._initPlugins = function () {
        var plugins = this.config.plugins;
        if (!plugins || !plugins.length) {
            return;
        }
        var applyPluginPromises = [];
        for (var _i = 0, plugins_1 = plugins; _i < plugins_1.length; _i++) {
            var plugin = plugins_1[_i];
            this._throwIfInvalidPlugin(plugin);
            applyPluginPromises.push((plugin.apply(this) || Promise.resolve()));
        }
        return Promise.all(applyPluginPromises);
    };
    InjexContainer.prototype._throwIfAlreadyDefined = function (name) {
        if (this._moduleRegistry.has(name)) {
            throw new errors_1.DuplicateDefinitionError(name);
        }
    };
    InjexContainer.prototype._throwIfModuleExists = function (name) {
        if (this._modules.has(name)) {
            throw new errors_1.DuplicateDefinitionError(name);
        }
    };
    InjexContainer.prototype._throwIfInvalidPlugin = function (plugin) {
        if (!plugin.apply || !stdlib_1.isFunction(plugin.apply)) {
            throw new errors_1.InvalidPluginError(plugin);
        }
    };
    InjexContainer.prototype._createHooks = function () {
        this.hooks = {
            beforeRegistration: new stdlib_1.Hook(),
            afterRegistration: new stdlib_1.Hook(),
            beforeCreateModules: new stdlib_1.Hook(),
            afterModuleCreation: new stdlib_1.Hook(),
            afterCreateModules: new stdlib_1.Hook(),
            beforeCreateInstance: new stdlib_1.Hook(),
            bootstrapRun: new stdlib_1.Hook(),
            bootstrapError: new stdlib_1.Hook(),
            bootstrapComplete: new stdlib_1.Hook(),
        };
    };
    InjexContainer.prototype._createModules = function () {
        var _this = this;
        this.hooks.beforeCreateModules.call();
        this._moduleRegistry.forEach(function (item) { return _this._createModule(item); });
        this.hooks.afterCreateModules.call();
    };
    InjexContainer.prototype._createModule = function (item) {
        var metadata = metadataHandlers_1.default.getMetadata(item.prototype);
        this._throwIfModuleExists(metadata.name);
        var module;
        switch (true) {
            case metadata.lazy:
                var _a = this._createLazyModuleFactoryMethod(item, metadata), loaderFn = _a[0], loaderInstance = _a[1];
                metadata.lazyLoader = loaderInstance;
                module = loaderFn;
                break;
            case metadata.singleton:
                module = this._createInstance(item, constants_1.EMPTY_ARGS);
                break;
            default:
                module = this._createModuleFactoryMethod(item, metadata);
        }
        var moduleWithMetadata = {
            module: module, metadata: metadata
        };
        this._modules.set(metadata.name, moduleWithMetadata);
        if (metadata.aliases) {
            var alias = void 0;
            for (var i = 0, len = metadata.aliases.length; i < len; i++) {
                alias = metadata.aliases[i];
                if (!this._aliases.has(alias)) {
                    this._aliases.set(alias, []);
                }
                this._aliases.get(alias).push(moduleWithMetadata);
            }
        }
        this.hooks.afterModuleCreation.call(moduleWithMetadata);
    };
    InjexContainer.prototype._createLazyModuleFactoryMethod = function (construct, metadata) {
        var self = this;
        var loaderInstance = this._createInstance(construct, constants_1.EMPTY_ARGS);
        function loaderFn() {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                var Ctor, lazyMetadata, lazyInstance;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, loaderInstance.import.apply(loaderInstance, args)];
                        case 1:
                            Ctor = _a.sent();
                            lazyMetadata = metadataHandlers_1.default.getMetadata(Ctor.prototype);
                            lazyInstance = self._createInstance(Ctor, args);
                            self._injectModuleDependencies(lazyInstance);
                            return [4 /*yield*/, self._invokeModuleInitMethod(lazyInstance, lazyMetadata)];
                        case 2:
                            _a.sent();
                            return [2 /*return*/, lazyInstance];
                    }
                });
            });
        }
        return [loaderFn, loaderInstance];
    };
    InjexContainer.prototype._createModuleFactoryMethod = function (construct, metadata) {
        var self = this;
        return function factory() {
            var _this = this;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var instance = self._createInstance(construct, args);
            self._injectModuleDependencies(instance);
            var initValue = self._invokeModuleInitMethod(instance, metadata);
            if (stdlib_1.isPromise(initValue)) {
                return initValue
                    .then(function () { return instance; })
                    .catch(function (err) { return _this._onInitModuleError(metadata, err); });
            }
            return instance;
        };
    };
    InjexContainer.prototype._initializeModules = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(Array
                            .from(this._modules.values())
                            .map(function (_a) {
                            var module = _a.module, metadata = _a.metadata;
                            if (metadata && metadata.singleton) {
                                _this._injectModuleDependencies(metadata.lazyLoader || module);
                            }
                            return { module: module, metadata: metadata };
                        })
                            .map(function (_a) {
                            var module = _a.module, metadata = _a.metadata;
                            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_b) {
                                    if (metadata && metadata.singleton) {
                                        return [2 /*return*/, this._invokeModuleInitMethod(metadata.lazyLoader || module, metadata)];
                                    }
                                    return [2 /*return*/];
                                });
                            });
                        }))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    InjexContainer.prototype._invokeModuleInitMethod = function (module, metadata) {
        var chain = [];
        metadataHandlers_1.default.forEachProtoMetadata(module, function (_, meta) {
            if ((meta === null || meta === void 0 ? void 0 : meta.initMethod) && chain.indexOf(meta.initMethod) < 0) {
                chain.push(meta.initMethod);
            }
        });
        try {
            var promises = [];
            for (var i = chain.length - 1; i >= 0; i--) {
                var res = module[chain[i]].call(module);
                if (stdlib_1.isPromise(res)) {
                    promises.push(res);
                }
            }
            if (promises.length) {
                return Promise.all(promises);
            }
        }
        catch (e) {
            this._onInitModuleError(metadata.name, e);
        }
    };
    InjexContainer.prototype._onInitModuleError = function (moduleName, err) {
        this._logger.error(err);
        throw new errors_1.InitializeMuduleError(moduleName);
    };
    InjexContainer.prototype._createInstance = function (construct, args) {
        if (args === void 0) { args = []; }
        this.hooks.beforeCreateInstance.call(construct, args);
        return new (construct.bind.apply(construct, tslib_1.__spreadArrays([void 0], args)))();
    };
    InjexContainer.prototype.getModuleDefinition = function (moduleNameOrType) {
        if (this._modules.has(moduleNameOrType)) {
            return this._modules.get(moduleNameOrType);
        }
        else if (moduleNameOrType instanceof Function && metadataHandlers_1.default.hasMetadata(moduleNameOrType.prototype)) {
            var metadata = metadataHandlers_1.default.getMetadata(moduleNameOrType.prototype);
            return this._modules.get(metadata.name);
        }
        return null;
    };
    InjexContainer.prototype._injectModuleDependencies = function (module) {
        var _this = this;
        var self = this;
        metadataHandlers_1.default.forEachProtoMetadata(module, function (_, meta) {
            var dependencies = meta.dependencies || [];
            var aliasDependencies = meta.aliasDependencies || [];
            var factoryDependencies = meta.factoryDependencies || [];
            var paramDependencies = meta.paramDependencies || [];
            var _loop_1 = function (label, value) {
                Object.defineProperty(module, label, {
                    configurable: true,
                    get: function () { return _this.get(value) || null; }
                });
            };
            for (var _i = 0, dependencies_1 = dependencies; _i < dependencies_1.length; _i++) {
                var _a = dependencies_1[_i], label = _a.label, value = _a.value;
                _loop_1(label, value);
            }
            var _loop_2 = function (label, alias, keyBy) {
                Object.defineProperty(module, label, {
                    configurable: true,
                    get: function () { return _this.getAlias(alias, keyBy); }
                });
            };
            for (var _b = 0, aliasDependencies_1 = aliasDependencies; _b < aliasDependencies_1.length; _b++) {
                var _c = aliasDependencies_1[_b], label = _c.label, alias = _c.alias, keyBy = _c.keyBy;
                _loop_2(label, alias, keyBy);
            }
            var _loop_3 = function (label, value) {
                var factory = _this.get(value);
                if (!factory) {
                    throw new errors_1.FactoryModuleNotExistsError(value);
                }
                var item = factory();
                Object.defineProperty(module, label, {
                    configurable: true,
                    get: function () { return stdlib_1.isPromise(item) ? item.then(function (instance) { return instance; }) : item; }
                });
            };
            for (var _d = 0, factoryDependencies_1 = factoryDependencies; _d < factoryDependencies_1.length; _d++) {
                var _e = factoryDependencies_1[_d], label = _e.label, value = _e.value;
                _loop_3(label, value);
            }
            var _loop_4 = function (methodName, index, value) {
                var org = module[methodName];
                module[methodName] = function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    args[index] = self.get(value);
                    return org.apply(module, args);
                };
            };
            for (var _f = 0, paramDependencies_1 = paramDependencies; _f < paramDependencies_1.length; _f++) {
                var _g = paramDependencies_1[_f], methodName = _g.methodName, index = _g.index, value = _g.value;
                _loop_4(methodName, index, value);
            }
        });
    };
    InjexContainer.prototype._register = function (item) {
        var _a;
        if ((_a = !(item === null || item === void 0 ? void 0 : item.prototype)) !== null && _a !== void 0 ? _a : !metadataHandlers_1.default.hasMetadata(item.prototype)) {
            return;
        }
        var metadata = metadataHandlers_1.default.getMetadata(item.prototype);
        if (!metadata || !metadata.name) {
            return;
        }
        this._throwIfAlreadyDefined(metadata.name);
        this._moduleRegistry.set(metadata.name, item);
    };
    /**
     * Manually add module with metadata
     *
     * @param item - Module with metadata to add
     */
    InjexContainer.prototype.addModule = function (item) {
        if (!metadataHandlers_1.default.hasMetadata(item.prototype)) {
            this._logger.debug("You're trying to add module without any metadata.");
            return this;
        }
        this._register(item);
        this._createModule(item);
        var _a = this.getModuleDefinition(item), module = _a.module, metadata = _a.metadata;
        if (metadata.singleton) {
            this._injectModuleDependencies(metadata.lazyLoader || module);
            this._invokeModuleInitMethod(metadata.lazyLoader || module, metadata);
        }
        return this;
    };
    /**
     * Manually add object to the container as singleton
     *
     * @param obj - Object to add
     * @param name - Name of the object
     */
    InjexContainer.prototype.addObject = function (obj, name) {
        this._throwIfModuleExists(name);
        var metadata = { singleton: true, item: obj, name: name };
        this._modules.set(name, { module: obj, metadata: metadata });
        return this;
    };
    /**
     * Remove object from container
     *
     * @param name - Name of the object
     */
    InjexContainer.prototype.removeObject = function (name) {
        this._modules.delete(name);
        this._moduleRegistry.delete(name);
        return this;
    };
    InjexContainer.prototype.get = function (itemNameOrType) {
        var definition = this.getModuleDefinition(itemNameOrType);
        if (!definition) {
            return constants_1.UNDEFINED;
        }
        return definition.module;
    };
    InjexContainer.prototype.getAlias = function (alias, keyBy) {
        var aliasModules = this._aliases.get(alias) || [];
        var useSet = !keyBy;
        var mapOrSet = useSet ? [] : {};
        for (var _i = 0, aliasModules_1 = aliasModules; _i < aliasModules_1.length; _i++) {
            var aliasModule = aliasModules_1[_i];
            if (useSet) {
                mapOrSet.push(aliasModule.module);
            }
            else {
                var keyValue = void 0;
                switch (true) {
                    case aliasModule.metadata.lazy:
                        keyValue = aliasModule.metadata.lazyLoader[keyBy];
                        break;
                    case aliasModule.metadata.singleton:
                        keyValue = aliasModule.module[keyBy];
                        break;
                    default:
                        keyValue = aliasModule.metadata.item[keyBy];
                }
                if (keyValue) {
                    mapOrSet[keyValue] = aliasModule.module;
                }
            }
        }
        return mapOrSet;
    };
    return InjexContainer;
}());
exports.default = InjexContainer;
