export enum TimeRangeType {
    Today = 'today',
    Yesterday = 'yesterday',
    ThisWeek = 'this_week',
    Last7Days = 'last_7_days',
    LastWeek = 'last_week',
    ThisMonth = 'this_month',
    Last30Days = 'last_30_days',
    LastMonth = 'last_month',
    ThisYear = 'this_year',
    LastYear = 'last_year',
    Custom = 'custom'
}