import {EnvPlugin} from '@injex/env-plugin';
import {ReactPlugin} from '@injex/react-plugin';
import {Injex} from '@injex/webpack';
import * as dayjs from 'dayjs';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import {configure} from 'mobx';
import './modules/ui/styles/main.scss';
import reportWebVitals from './reportWebVitals';

dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);
dayjs.extend(customParseFormatPlugin);

dayjs.Ls.en.weekStart = 1;

configure({
    enforceActions: 'never',
});

Injex.create({
    // @ts-ignore
    resolveContext: () => require.context(__dirname, true, /\.mdl\.tsx?$/),
    plugins: [
        new EnvPlugin({
            defaults: {
                recaptchaSiteKey: '6LdpvEAUAAAAAADPotdQeuDkMG5xp9LMrARwqRQ9',
                api: process.env.REACT_APP_MODE === 'staging'
                    ? 'https://publishers-api-staging.vidazoo.com'
                    : 'https://publishers-api.vidazoo.com',
            }
        }),
        new ReactPlugin({
            rootElementOrSelector: '#root',
        })
    ]
}).bootstrap();

reportWebVitals(console.log);