import {alias, define, init, inject, singleton} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import IDisposable from '../../../common/interfaces/IDisposable';
import {isBoolean} from '../../../stdlib/assert';
import Hook from '../../../stdlib/Hook';
import {RouterService} from '../../router/services/routerService.mdl';
import {AuthStatus} from '../../session/common/enums';
import {SessionManager} from '../../session/managers/sessionManager.mdl';
import {AccountsAPI} from '../api/accounts.mdl';
import IAccountMetadata, {IAccount} from '../interfaces/IAccountMetadata';

export type AccountHooks = {
    metadataLoad: Hook<[metadata: IAccountMetadata]>;
};

@define()
@singleton()
@alias('Disposable')
export class AccountManager implements IDisposable {
    @inject() private sessionManager: SessionManager;
    @inject() private accountsAPI: AccountsAPI;
    @inject() private routerService: RouterService;

    @observable public accounts: IAccount[];
    @observable public selectedAccountId: string;
    @observable.ref public selectedAccount: IAccount;
    @observable public userDismissOnBoardingAlert: boolean;

    public hooks: AccountHooks;

    constructor() {
        makeObservable(this);
        this.accounts = [];
        this.selectedAccountId = '';
        this.selectedAccount = null;
        this.userDismissOnBoardingAlert = Boolean(window.sessionStorage.getItem('obad'));
        this.hooks = {
            metadataLoad: new Hook()
        };
    }

    @init()
    protected initialize() {
        this.sessionManager.hooks.statusChange.tap(this._onSessionStatusChange, null, this);
        this.sessionManager.hooks.loginSuccess.tapAsync(this._onLogin, null, this);
    }

    public toggleOnBoardingAlert(force?: boolean) {
        this.userDismissOnBoardingAlert = isBoolean(force) ? force : !this.userDismissOnBoardingAlert;
        window.sessionStorage.setItem('obad', String(this.userDismissOnBoardingAlert));
    }

    public selectAccount(accountId: string, reload?: boolean) {
        if (accountId === this.selectedAccountId) {
            return;
        }

        this.sessionManager.accountsSdk.auth.setSelectedAccounts(accountId);

        this.selectedAccountId = accountId;
        this.selectedAccount = this.accounts.find((account) => account._id === accountId);

        if (reload) {
            this.routerService.replace('/');
            window.location.reload();
        }
    }

    private _onSessionStatusChange(status: AuthStatus) {
        if (status !== AuthStatus.LoggedIn) {
            this.userDismissOnBoardingAlert = false;
        }
    }

    private async _onLogin() {
        const accountMetadata = await this.accountsAPI.me();
        this.accounts = observable.array(accountMetadata.accounts, {deep: false});
        const [accontId] = this.sessionManager.accountsSdk.auth.selectedAccounts;
        this.selectAccount(accontId || this.accounts[0]._id);
        this.hooks.metadataLoad.call(accountMetadata);
    }

    public dispose(): void {
        this.accounts = [];
        this.selectedAccountId = '';
        this.selectedAccount = null;
    }
}