import {alias, define, init, inject, singleton} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import IDisposable from '../../../common/interfaces/IDisposable';
import {SessionManager} from '../../session/managers/sessionManager.mdl';
import {Theme} from '../common/enums';
import themes from '../styles/themes.module.scss';

const THEME_STORAGE_KEY = 'theme';
@define()
@singleton()
@alias('Disposable')
export class ThemeManager implements IDisposable {

    @inject() private sessionManager: SessionManager;
    @observable public theme: Theme;

    constructor() {
        makeObservable(this);
        this.toggleTheme = this.toggleTheme.bind(this);
    }

    @init()
    protected initialize() {
        this.sessionManager.hooks.loginSuccess.tap(this._onLogin, null, this);
    }

    private _onLogin() {
        this.theme = window.localStorage.getItem(THEME_STORAGE_KEY) as Theme || Theme.Light;
        this.setTheme(this.theme, false);
    }

    public setTheme(theme: Theme, persist: boolean) {
        document.documentElement.classList.remove(themes[this.theme]);
        this.theme = theme;
        document.documentElement.classList.add(themes[theme]);

        if (persist) {
            window.localStorage.setItem(THEME_STORAGE_KEY, theme);
        }
    }

    public toggleTheme() {
        this.setTheme(this.theme === Theme.Light ? Theme.Dark : Theme.Light, true);
    }

    public dispose(): void {
        document.documentElement.classList.remove(themes[this.theme]);
    }
}