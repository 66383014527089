import {alias, define, init, inject, singleton} from '@injex/core';
import timeFormat from 'hh-mm-ss';
import IDisposable from '../../../common/interfaces/IDisposable';
import {convertUTCOffsetToMomentTimezone, formatNumber, guid} from '../../../stdlib/utils';
import IAccountMetadata, {IAccountActivityEntry} from '../../account/interfaces/IAccountMetadata';
import {AccountManager} from '../../account/managers/accountManager.mdl';
import {SessionManager} from '../../session/managers/sessionManager.mdl';
import {SocketService} from '../../socket/services/socketService.mdl';
import {TimeManager} from '../../time/managers/timeManager.mdl';
import ICompiledReportingField from '../interfaces/ICompiledReportingFiled';
import IReportParams from '../interfaces/IReportParams';
import IReportResponse from '../interfaces/IReportResponse';

export type ReportRequest = {
    id: string;
    params: IReportParams;
    promise: {
        resolve: (response: IReportResponse[]) => void,
        reject: (err: Error) => void,
    }
};

const defaultNumberFormat = (value: number) => new Intl.NumberFormat('en-US', {maximumFractionDigits: value >= 100 ? 0 : 2}).format(value);
const timeFormats = ['hh:mm', 'mm:ss', 'hh:mm:ss'];
const NUMBER_FORMAT_REGEXP = /(.?)\.(0+)(.?)/;

@define()
@singleton()
@alias('Disposable')
export class ReportingManager implements IDisposable {
    @inject() private socketService: SocketService;
    @inject() private sessionManager: SessionManager;
    @inject() private accountManager: AccountManager;
    @inject() private timeManager: TimeManager;

    private _verticalFieldsMeta: { [index: string]: { [index: string]: ICompiledReportingField; }; };
    private _requestsQueue: { [index: string]: ReportRequest; };
    private _queueTimer: number;

    constructor() {
        this._requestsQueue = {};
    }

    @init()
    protected initialize() {
        this.accountManager.hooks.metadataLoad.tap(this._onAccountMetadata, null, this);
    }

    private _onAccountMetadata(metadata: IAccountMetadata) {
        const {fields} = metadata.reportingMetaData;
        this._verticalFieldsMeta = {};

        for (const vertical in fields) {
            if (!this._verticalFieldsMeta[vertical]) {
                this._verticalFieldsMeta[vertical] = {};
            }

            for (let i = 0, len = fields[vertical].length; i < len; i++) {
                const metadata = fields[vertical][i];

                let formatFn = defaultNumberFormat;
                const format = metadata.format;
                if (format) {
                    if (timeFormats.includes(metadata.format)) {
                        formatFn = (value: any) => timeFormat.fromS(Math.floor(value), format);
                    } else {
                        const matches = format.match(NUMBER_FORMAT_REGEXP);
                        if (matches) {
                            const [pre, decimals, post] = matches.slice(1);
                            formatFn = (value: any) => `${pre}${formatNumber(value, value >= 100 ? 0 : decimals.length)}${post}`;
                        }
                    }
                }

                this._verticalFieldsMeta[vertical][fields[vertical][i].value] = {
                    formatFn,
                    ...metadata,
                };
            }
        }
    }

    public getFieldMetadataByVertical(fieldName: string, vertical: string): ICompiledReportingField {
        return this._verticalFieldsMeta[vertical]?.[fieldName] ?? null;
    }

    /**
     * Adds a request to queue to prevent multiple report requests
     * when sent synchronously
     *
     * @param params IReportParams
     */
    public addRequestToQueue(params: IReportParams): Promise<IReportResponse[]> {
        window.clearTimeout(this._queueTimer);

        return new Promise((resolve, reject) => {
            const id = guid();
            this._requestsQueue[id] = {
                id,
                params,
                promise: {
                    resolve, reject
                }
            };

            this._queueTimer = window.setTimeout(() => this._flushRequestsQueue(), 10);
        });
    }

    public createActivityReportParams(activity: IAccountActivityEntry, withCompare?: boolean): IReportParams {
        let [from, to] = this.timeManager.currentTimeRangeResolved;
        const {accounts} = this.sessionManager.getRequestContext();

        from = this.timeManager.withTimezoneOffset(from);
        to = this.timeManager.withTimezoneOffset(to);

        const dto: IReportParams = {
            timezone: convertUTCOffsetToMomentTimezone(this.timeManager.currentTimezoneMetadata.utc),
            verticalType: activity.verticalType,
            ids: activity.publisherIds,
            activityId: activity._id,
            selectedAccount: accounts[0],
            groups: [],
            fields: [],
            from,
            to
        };

        if (withCompare) {
            const [compareFrom, compareTo] = this.timeManager.currentTimeRange.resolveCompare();

            if (compareFrom && compareTo) {
                dto.compareFrom = this.timeManager.withTimezoneOffset(compareFrom);
                dto.compareTo = this.timeManager.withTimezoneOffset(compareTo);
            }
        }

        return dto;
    }

    private async _flushRequestsQueue() {
        const allRequests = {...this._requestsQueue};
        this._requestsQueue = {};

        const payload: { [index: string]: IReportParams; } = {};

        for (const requestId in allRequests) {
            const {params} = allRequests[requestId];
            const {compareFrom, compareTo} = params;

            delete params.compareFrom;
            delete params.compareTo;

            payload[requestId] = params;

            if (compareFrom && compareTo) {
                payload[`${requestId}_compare`] = {
                    ...params,
                    from: compareFrom,
                    to: compareTo
                };
            }
        }

        try {
            const response = await this.getMultipleReports(payload);
            for (const requestId in allRequests) {
                const {promise} = allRequests[requestId];
                if (response[`${requestId}_compare`]) {
                    this._combineReports(response[requestId], response[`${requestId}_compare`]);
                }
                promise.resolve(response[requestId]);
            }
        } catch (e) {
            this._rejectAllRequests(e);
        }
    }

    private _combineReports(results: IReportResponse[], compareResults: IReportResponse[]) {
        for (var i = 0, len = results.length; i < len; i++) {
            if (Object.keys(results[i].groups).length === 0 && compareResults[i]) {
                results[i].fieldsCompared = compareResults[i].fields;
            } else {
                resultsLoop: for (let j = 0, jLen = compareResults.length; j < jLen; j++) {
                    for (const group in results[i].groups) {
                        if (compareResults[j].groups[group] !== results[i].groups[group]) {
                            continue resultsLoop;
                        }
                    }
                    results[i].fieldsCompared = compareResults[j].fields;
                }
            }
        }
    }

    private _rejectAllRequests(e?: Error) {
        for (const requestId in this._requestsQueue) {
            const {promise} = this._requestsQueue[requestId];
            promise.reject(e);
        }
    }

    public getSingleReport(data: IReportParams): Promise<IReportResponse> {
        return this.socketService.request<IReportResponse>('getReport', data);
    }

    public getMultipleReports(data: { [index: string]: IReportParams; }): Promise<{ [index: string]: IReportResponse[]; }> {
        return this.socketService.request<{ [index: string]: IReportResponse[]; }>('getReportMulti', data);
    }

    public dispose(): void {
        window.clearTimeout(this._queueTimer);
        this._rejectAllRequests();
        this._requestsQueue = {};
        this._verticalFieldsMeta = {};
    }
}