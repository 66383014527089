"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var InjexContext_1 = require("./InjexContext");
function InjexProvider(_a) {
    var container = _a.container, children = _a.children;
    var inject = container.get.bind(container);
    var injectAlias = container.getAlias.bind(container);
    var contextValue = React.useMemo(function () { return ({
        container: container,
        inject: inject,
        injectAlias: injectAlias
    }); }, []);
    return (React.createElement(InjexContext_1.default.Provider, { value: contextValue }, children));
}
exports.default = InjexProvider;
