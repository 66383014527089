"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.alias = void 0;
var metadataHandlers_1 = require("../metadataHandlers");
function alias() {
    var names = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        names[_i] = arguments[_i];
    }
    return function (targetConstructor) {
        for (var i = 0, len = names.length; i < len; i++) {
            metadataHandlers_1.default.pushMetadata(targetConstructor.prototype, "aliases", names[i]);
        }
    };
}
exports.alias = alias;
