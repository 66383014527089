"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInjex = void 0;
var React = require("react");
var InjexContext_1 = require("../InjexContext");
/**
 * Access to Injex `inject` and `injectAlias` decorators
 * inside a React component to inject container dependencies.
 *
 * @returns [Inject, InjectAlias]
 */
function useInjex() {
    var context = React.useContext(InjexContext_1.default);
    return React.useMemo(function () { return [context.inject, context.injectAlias]; }, []);
}
exports.useInjex = useInjex;
