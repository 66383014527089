import {define, singleton} from '@injex/core';
import {computed, makeObservable, observable} from 'mobx';
import {isAndroidChrome, isIOSSafari} from '../../../stdlib/assert';

const isInWebAppiOS = ((window.navigator as any).standalone === true);
const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);

const ALERT_DISMISS_STORAGE_KEY = 'bookmark_alert_dismiss';
const IS_APP_MODE = isInWebAppiOS || isInWebAppChrome;

@define()
@singleton()
export class BookmarkManager {
    @observable public userDismissAlert: boolean;

    constructor() {
        makeObservable(this);
        this.userDismissAlert = Boolean(window.localStorage.getItem(ALERT_DISMISS_STORAGE_KEY));
    }

    public dismiss() {
        this.userDismissAlert = true;
        window.localStorage.setItem(ALERT_DISMISS_STORAGE_KEY, '1');
    }

    @computed get showAlert(): boolean {
        return !IS_APP_MODE && !this.userDismissAlert && (isIOSSafari || isAndroidChrome);
    }
}