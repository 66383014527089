import {define, Factory, init, inject} from '@injex/core';
import dayjs from 'dayjs';
import {makeObservable, observable} from 'mobx';
import {IAccountActivityEntry} from '../../account/interfaces/IAccountMetadata';
import {ReportingManager} from '../../reporting/managers/reportingManager.mdl';
import {TimeManager} from '../../time/managers/timeManager.mdl';
import {ActivitySummaryField} from './ActivitySummaryField.mdl';

/**
 * This values are hard coded until we will manage fields ordering
 * in publishers desktop account activity.
 */
const UNIQUE_FIELDS_INDEX_ASC = ['RPM', 'CPM', 'Revenue'];

@define()
export class ActivitySummary {
    @inject() private reportingManager: ReportingManager;
    @inject() private timeManager: TimeManager;
    @inject(ActivitySummaryField) private _createActivitySummaryField: Factory<ActivitySummaryField>;

    public activity: IAccountActivityEntry;

    @observable public summaryFields: ActivitySummaryField[];
    @observable public isLoading: boolean;
    @observable public didLoad: boolean;

    constructor(activity: IAccountActivityEntry) {
        makeObservable(this);
        this.activity = activity;
        this.didLoad = false;
        this.isLoading = false;
    }

    @init()
    protected initialize() {
        this.summaryFields = observable.array(this._createSummaryFields());
        this.loadSummary();
    }

    public getSummaryField(summaryFieldId: string): ActivitySummaryField {
        return this.summaryFields.find((summaryField) => summaryField.id === summaryFieldId);
    }

    public async loadSummary(useForce?: boolean) {
        if (this.isLoading || (this.didLoad && !useForce)) {
            return;
        }

        this.isLoading = true;

        this.clearFieldsChartData();

        await Promise.all(
            this.summaryFields.map((summaryField) => this._loadSummaryField(summaryField))
        );

        this.isLoading = false;
        this.didLoad = true;
    }

    public async loadSummaryFieldChartData(summaryFieldId: string, useForce?: boolean) {
        const summaryField = this.getSummaryField(summaryFieldId);

        if (summaryField.isLoadingChart || (summaryField.didLoadChart && !useForce)) {
            return;
        }

        const params = this.reportingManager.createActivityReportParams(this.activity);
        params.fields.push(summaryField.field);

        const diff = this.timeManager.timeRangeDiff();
        const timeGroup = diff <= 1 ? 'hour' : 'day';
        const format = timeGroup === 'hour' ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
        params.groups.push(timeGroup);

        summaryField.isLoadingChart = true;

        const response = await this.reportingManager.addRequestToQueue(params);

        let chartData = [];
        for (let i = 0, len = response.length; i < len; i++) {
            const {groups, fields} = response[i];

            chartData.push([
                dayjs.utc(groups[timeGroup], format).unix() * 1000,
                Number(fields[summaryField.field].toFixed(2))
            ]);
        }

        chartData = chartData.sort((pointA, pointB) => {
            return pointA[0] - pointB[0];
        });

        summaryField.setChartData(chartData);
        summaryField.isLoadingChart = false;
        summaryField.didLoadChart = true;
    }

    public clearFieldsChartData() {
        this.summaryFields.forEach((summaryField) => summaryField.clearChartData());
    }

    private async _loadSummaryField(summaryField: ActivitySummaryField): Promise<void> {
        const params = this.reportingManager.createActivityReportParams(this.activity, true);
        params.fields.push(summaryField.field);

        const response = await this.reportingManager.addRequestToQueue(params);

        summaryField.setValue(response[0].fields[summaryField.field], response[0].fieldsCompared[summaryField.field]);
    }

    private _createSummaryFields(): ActivitySummaryField[] {
        return this.activity.fields.filter((field) => field.required).sort((fieldA, fieldB) => {
            return UNIQUE_FIELDS_INDEX_ASC.indexOf(fieldB.label) - UNIQUE_FIELDS_INDEX_ASC.indexOf(fieldA.label);
        }).map<ActivitySummaryField>((field) => {
            const metadata = this.reportingManager.getFieldMetadataByVertical(field.value, this.activity.verticalType);
            return this._createActivitySummaryField(field, metadata);
        });
    }
}