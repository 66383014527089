import {define, init, inject} from '@injex/core';
import {computed, makeObservable, observable} from 'mobx';
import {guid} from '../../../stdlib/utils';
import {IAccountActivityEntry} from '../../account/interfaces/IAccountMetadata';
import {TimeManager} from '../../time/managers/timeManager.mdl';
import {ActivitiesManager} from '../managers/activitiesManager.mdl';
import {ActivitySummary} from './ActivitySummary.mdl';
import {ActivitySummaryField} from './ActivitySummaryField.mdl';

@define()
export class MockActivity {
    @inject() private activitiesManager: ActivitiesManager;
    @inject() private timeManager: TimeManager;

    public id: string;
    public activity: IAccountActivityEntry;
    public activitySummary: ActivitySummary;

    @observable public selectedSummaryFieldId: string;

    constructor(activity: IAccountActivityEntry) {
        makeObservable(this);
        // this.id = guid();
        // this.refresh = this.refresh.bind(this);
        // this.activity = activity;
    }

    @init()
    protected initialize() {
        if (!this.activity) {
            return;
        }

        // this.timeManager.hooks.timeChange.tap(this._onTimeChange, null, this);

        // this.activitySummary = this.activitiesManager.getOrCreateActivitySummary(this.activity._id);
        // this.selectSummaryFieldId(this.activitySummary.summaryFields[0].id);
    }

    private _onTimeChange() {
        this.refresh();
    }

    public refresh() {
        if (!this.activity) {
            return;
        }

        this.activitySummary.clearFieldsChartData();

        return Promise.all([
            this.activitySummary.loadSummary(true),
            this.activitySummary.loadSummaryFieldChartData(this.selectedSummaryFieldId, true)
        ]);
    }

    public selectSummaryFieldId(selectedSummaryFieldId: string) {
        this.selectedSummaryFieldId = selectedSummaryFieldId;
        // this.activitySummary.loadSummaryFieldChartData(selectedSummaryFieldId);
    }

    @computed public get selectedSummaryField(): ActivitySummaryField {
        return this.activitySummary.summaryFields.find((summaryField) => summaryField.id === this.selectedSummaryFieldId);
    }

    public destroy() {
        this.timeManager.hooks.timeChange.untap(this._onTimeChange, this);
        this.activity = null;
    }
}