import {alias, define, init, inject, singleton} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import IDisposable from '../../../common/interfaces/IDisposable';
import {ActivitiesManager} from '../../activity/managers/activitiesManager.mdl';
import {ActivitySummary} from '../../activity/models/ActivitySummary.mdl';
import {SessionManager} from '../../session/managers/sessionManager.mdl';
import {TimeManager} from '../../time/managers/timeManager.mdl';

@define()
@singleton()
@alias('Disposable')
export class HomeManager implements IDisposable {

    @inject() private sessionManager: SessionManager;
    @inject() private activitiesManager: ActivitiesManager;
    @inject() private timeManager: TimeManager;

    @observable public activities: ActivitySummary[];

    constructor() {
        makeObservable(this);
        this.refresh = this.refresh.bind(this);
        this.activities = observable.array([], {deep: false});
    }

    @init()
    protected initialize() {
        this.sessionManager.hooks.loginSuccess.tap(this._onLogin, null, this);
        this.timeManager.hooks.timeChange.tap(this._onTimeChange, null, this);
    }

    private _onLogin() {
        const activities = this.activitiesManager.accountActivities;
        const homeActivities = [];
        for (let i = 0, len = activities.length; i < len; i++) {
            homeActivities.push(
                this.activitiesManager.getOrCreateActivitySummary(activities[i]._id)
            );
        }

        this.activities = homeActivities;
    }

    private _onTimeChange() {
        this.refresh();
    }

    public refresh() {
        return Promise.all(
            this.activities.map(activity => activity.loadSummary(true))
        );
    }

    public dispose(): void {
        this.activities = observable.array([], {deep: false});
    }
}