import {alias, define, singleton} from '@injex/core';
import dayjs from 'dayjs';
import {TimeRangeType} from '../common/enums';
import ITimeRange from '../interfaces/ITimeRange';

@define()
@singleton()
@alias('TimeRange')
export class YesterdayTimeRange implements ITimeRange {
    public readonly rangeType: TimeRangeType = TimeRangeType.Yesterday;
    public readonly order: number = 20;
    public readonly displayName: string = 'Yesterday';

    public resolve(): [from: number, to: number, label: string] {
        const [from, to] = [
            dayjs().utc().subtract(1, 'day').startOf('day'),
            dayjs().utc().startOf('day')
        ];

        return [
            from.unix(),
            to.unix(),
            from.format('DD.MM')
        ];
    }

    public resolveCompare(): [compareFrom: number, compareTo: number] {
        const [compareFrom, compareTo] = [
            dayjs().utc().subtract(2, 'day').startOf('day'),
            dayjs().utc().subtract(1, 'day').startOf('day')
        ];

        return [compareFrom.unix(), compareTo.unix()];
    }
}