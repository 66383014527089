import axios, {AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse} from 'axios';

export default class RequestAdapter {
    protected http: AxiosInstance;

    constructor(baseURL: string) {
        if (baseURL) {
            this.createHTTPClient(baseURL);
        }
    }

    protected createHTTPClient(baseURL: string) {
        this.http = axios.create({
            baseURL,
        });

        this.http.interceptors.response.use(
            this._onResponse.bind(this),
            this._onResponseError.bind(this)
        );
    }

    protected request<T = any>(config: AxiosRequestConfig): AxiosPromise<T> {
        return this.http.request(config);
    }

    private _onResponse(response) {
        return response;
    }

    private _onResponseError(error: AxiosError) {
        if (error.response.status === 401 || error.response.status === 403) {
            this._handleUnauthorize();
        } else {
            return Promise.reject(error);
        }
    }

    private _handleUnauthorize() {

    }
}