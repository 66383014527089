import {alias, define, singleton} from '@injex/core';
import dayjs from 'dayjs';
import {TimeRangeType} from '../common/enums';
import ITimeRange, {TimeRangeResolve, TimeRangeResolveCompare} from '../interfaces/ITimeRange';

@define()
@singleton()
@alias('TimeRange')
export class Last30DaysTimeRange implements ITimeRange {
    public readonly rangeType: TimeRangeType = TimeRangeType.Last30Days;
    public readonly order: number = 70;
    public readonly displayName: string = 'Last 30 Days';

    public resolve(): TimeRangeResolve {
        const [from, to] = [
            dayjs().utc().subtract(30, 'days').startOf('day'),
            dayjs().utc().startOf('day')
        ];

        return [
            from.unix(),
            to.unix(),
            `${from.format('DD.MM')}-${to.format('DD.MM')}`
        ];
    }

    public resolveCompare(): TimeRangeResolveCompare {
        const [compareFrom, compareTo] = [
            dayjs().utc().subtract(60, 'days').startOf('day'),
            dayjs().utc().subtract(30, 'days').endOf('hour')
        ];

        return [compareFrom.unix(), compareTo.unix()];
    }
}