const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function guid(len = 8): string {
    let g = '';
    for (let i = 0; i < len; i++) {
        g += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    }

    return g;
}

export function getTimezonOffsetFromUTC(utc: string): number {
    if (utc.endsWith('00:00')) {
        return 0;
    }

    return Number((utc.indexOf('-') > -1 ? utc.replace('-', '+') : utc.replace('+', '-')).replace(/[:0]/g, ''));
}

export function convertUTCOffsetToMomentTimezone(utc: string) {
    if (utc.endsWith('00:00')) {
        utc = '+0';
    } else {
        utc = (utc.indexOf('-') > -1 ? utc.replace('-', '+') : utc.replace('+', '-')).replace(/[:0]/g, '');
    }

    return `Etc/GMT${utc}`;
}

export function take<T>(arr: T[], count: number): T[] {
    return arr.slice(0, count);
}

export function formatNumber(num: number, fixed?: number) {
    var value = (num || 0).toString();
    var options: Intl.NumberFormatOptions = {};
    if (!isNaN(fixed)) {
        options.maximumFractionDigits = fixed;
    }
    return parseFloat(value).toLocaleString(undefined, options);
}