"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.injectAlias = void 0;
var metadataHandlers_1 = require("../metadataHandlers");
function injectAlias(alias, keyBy) {
    return function (targetPrototype, dependency) {
        metadataHandlers_1.default.pushMetadata(targetPrototype, "aliasDependencies", {
            label: dependency,
            alias: alias,
            keyBy: keyBy
        });
    };
}
exports.injectAlias = injectAlias;
