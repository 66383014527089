import {define, singleton} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import {isBoolean} from '../../../stdlib/assert';

@define()
@singleton()
export class MainMenuManager {
    @observable public isOpen: boolean;

    constructor() {
        makeObservable(this);
        this.isOpen = false;
    }

    public toggleMenu(force?: boolean) {
        this.isOpen = isBoolean(force) ? force : !this.isOpen;
    }
}