import {alias, define, Factory, inject, singleton} from '@injex/core';
import {makeObservable, observable, ObservableMap} from 'mobx';
import IDisposable from '../../../common/interfaces/IDisposable';
import {IAccountActivityEntry} from '../../account/interfaces/IAccountMetadata';
import {AccountManager} from '../../account/managers/accountManager.mdl';
import {ActivityInsights, IActivityInsightsDelegate} from '../models/ActivityInsights.mdl';
import {ActivitySummary} from '../models/ActivitySummary.mdl';

@define()
@singleton()
@alias('Disposable')
export class ActivitiesManager implements IDisposable {
    @inject() private accountManager: AccountManager;
    @inject(ActivitySummary) private _createActivitySummary: Factory<ActivitySummary>;
    @inject(ActivityInsights) private _createActivityInsights: Factory<ActivityInsights>;

    @observable public activitiesSummary: ObservableMap<string, ActivitySummary>;

    constructor() {
        makeObservable(this);
        this.activitiesSummary = new ObservableMap([]);
    }

    public getOrCreateActivitySummary(activityId: string): ActivitySummary {
        if (this.activitiesSummary.has(activityId)) {
            return this.activitiesSummary.get(activityId);
        }

        const activity = this.getActivityEntry(activityId);

        if (!activity) {
            return null;
        }

        const activitySummary = this._createActivitySummary(activity);

        this.activitiesSummary.set(activityId, activitySummary);

        return activitySummary;
    }

    public createActivityInsights(activityId: string, delegate: IActivityInsightsDelegate): ActivityInsights {
        const activity = this.getActivityEntry(activityId);

        if (!activity) {
            return null;
        }

        return this._createActivityInsights(activity, delegate);
    }

    public get accountActivities(): IAccountActivityEntry[] {
        return this.accountManager.selectedAccount.activities;
    }

    public getActivityEntry(activityId: string): IAccountActivityEntry {
        return this.accountActivities.find((activity) => activity._id === activityId);
    }

    public dispose(): void {
        this.activitiesSummary.clear();
    }
}