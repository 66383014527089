import {define} from '@injex/core';
import {computed, makeObservable, observable} from 'mobx';
import {guid} from '../../../stdlib/utils';
import {ActivityReportingEntry} from '../../account/interfaces/IAccountMetadata';
import ICompiledReportingField from '../../reporting/interfaces/ICompiledReportingFiled';

@define()
export class ActivitySummaryField {
    public readonly id: string;
    public label: string;
    public field: string;
    public didLoad: boolean;
    public didLoadChart: boolean;
    private _fieldMetadata: ICompiledReportingField;

    @observable public chartData: any[];
    @observable public isLoading: boolean;
    @observable public isLoadingChart: boolean;
    @observable public value: number;
    @observable public comparedValue: number;
    @observable public formattedValue: string;
    @observable public formattedComparedValue: string;

    constructor(field: ActivityReportingEntry, metadata: ICompiledReportingField) {
        makeObservable(this);
        this.id = guid();
        this._fieldMetadata = metadata;
        this.value = 0;
        this.comparedValue = 0;
        this.formattedValue = '';
        this.formattedComparedValue = '';
        this.label = field.label;
        this.field = field.value;
        this.chartData = [];

        this.didLoad = false;
        this.didLoadChart = false;
        this.isLoadingChart = false;
    }

    public setValue(value: number, comparedValue: number) {
        this.value = value;
        this.comparedValue = comparedValue;
        this.formattedValue = this.formatValue(value);
        this.formattedComparedValue = this.formatValue(comparedValue);
    }

    public formatValue(value: number): string {
        return this._fieldMetadata?.formatFn(value) ?? String(value);
    }

    public setChartData(data: any[]) {
        this.chartData = observable.array(data, {deep: false});
    }

    @computed public get changePercent(): number {
        return Math.round(this.value / this.comparedValue * 100 - 100);
    }

    public clearChartData() {
        this.didLoadChart = false;
        this.chartData = [];
    }
}