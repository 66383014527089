"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bootstrap = void 0;
var metadataHandlers_1 = require("../metadataHandlers");
var constants_1 = require("../constants");
function bootstrap() {
    return function (targetConstructor) {
        metadataHandlers_1.default.setMetadata(targetConstructor.prototype, "item", targetConstructor);
        metadataHandlers_1.default.setMetadata(targetConstructor.prototype, "name", constants_1.bootstrapSymbol);
        metadataHandlers_1.default.setMetadata(targetConstructor.prototype, "bootstrap", true);
        metadataHandlers_1.default.setMetadata(targetConstructor.prototype, "singleton", true);
    };
}
exports.bootstrap = bootstrap;
