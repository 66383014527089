"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.singleton = void 0;
var metadataHandlers_1 = require("../metadataHandlers");
function singleton() {
    return function (targetConstructor) {
        metadataHandlers_1.default.setMetadata(targetConstructor.prototype, "singleton", true);
    };
}
exports.singleton = singleton;
