"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.define = void 0;
var stdlib_1 = require("@injex/stdlib");
var metadataHandlers_1 = require("../metadataHandlers");
function getDependencyName(item, name) {
    return name || stdlib_1.toCamelCase(item.name);
}
function define(name) {
    return function (targetConstructor) {
        metadataHandlers_1.default.setMetadata(targetConstructor.prototype, "item", targetConstructor);
        metadataHandlers_1.default.setMetadata(targetConstructor.prototype, "name", getDependencyName(targetConstructor, name));
    };
}
exports.define = define;
