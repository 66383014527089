"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConstructorName = exports.isPromise = exports.isFunction = exports.toCamelCase = void 0;
function toCamelCase(str) {
    return str[0].toLowerCase() + str.slice(1);
}
exports.toCamelCase = toCamelCase;
function isFunction(predicate) {
    return typeof predicate === "function";
}
exports.isFunction = isFunction;
function isPromise(predicate) {
    return !!predicate && typeof predicate === "object" && isFunction(predicate.then) && isFunction(predicate.catch);
}
exports.isPromise = isPromise;
function getConstructorName(instance) {
    return (instance && instance.constructor && instance.constructor.name);
}
exports.getConstructorName = getConstructorName;
