"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FactoryModuleNotExistsError = exports.InvalidPluginError = exports.DuplicateDefinitionError = exports.InitializeMuduleError = void 0;
var tslib_1 = require("tslib");
var stdlib_1 = require("@injex/stdlib");
var InitializeMuduleError = /** @class */ (function (_super) {
    tslib_1.__extends(InitializeMuduleError, _super);
    function InitializeMuduleError(moduleName) {
        return _super.call(this, "Failed to initialize module '" + String(moduleName) + "'.") || this;
    }
    return InitializeMuduleError;
}(Error));
exports.InitializeMuduleError = InitializeMuduleError;
var DuplicateDefinitionError = /** @class */ (function (_super) {
    tslib_1.__extends(DuplicateDefinitionError, _super);
    function DuplicateDefinitionError(moduleName) {
        return _super.call(this, "Module '" + String(moduleName) + "' already defined.") || this;
    }
    return DuplicateDefinitionError;
}(Error));
exports.DuplicateDefinitionError = DuplicateDefinitionError;
var InvalidPluginError = /** @class */ (function (_super) {
    tslib_1.__extends(InvalidPluginError, _super);
    function InvalidPluginError(plugin) {
        return _super.call(this, "Plugin " + stdlib_1.getConstructorName(plugin) + " is not valid. Make sure the 'apply' method exists.") || this;
    }
    return InvalidPluginError;
}(Error));
exports.InvalidPluginError = InvalidPluginError;
var FactoryModuleNotExistsError = /** @class */ (function (_super) {
    tslib_1.__extends(FactoryModuleNotExistsError, _super);
    function FactoryModuleNotExistsError(factory) {
        return _super.call(this, (typeof factory === "string" ? factory : factory.name) + " is not a factory module.") || this;
    }
    return FactoryModuleNotExistsError;
}(Error));
exports.FactoryModuleNotExistsError = FactoryModuleNotExistsError;
