import {define, Factory, init, inject} from '@injex/core';
import {makeObservable, observable} from 'mobx';
import {isDefined} from '../../../stdlib/assert';
import {take} from '../../../stdlib/utils';
import {IAccountActivityEntry} from '../../account/interfaces/IAccountMetadata';
import {ReportingManager} from '../../reporting/managers/reportingManager.mdl';
import IActivityInsightCardEntry from '../interfaces/IActivityInsightCardEntry';
import {ActivityInsightCard} from './ActivityInsightCard.mdl';
import {ActivitySummaryField} from './ActivitySummaryField.mdl';

/**
 * In the future we will use insights defined on the
 * account activity by the admin or by the account owner.
 */
const DEFAULT_INSIGHTS = [
    {group: 'domain', title: 'Top Domains'},
    {group: 'country', title: 'Top Countries'},
];

export interface IActivityInsightsDelegate {
    selectedSummaryField: ActivitySummaryField;
}

@define()
export class ActivityInsights {
    @inject() private reportingManager: ReportingManager;
    @inject(ActivityInsightCard) private _createActivityInsightCard: Factory<ActivityInsightCard>;

    public activity: IAccountActivityEntry;
    public delegate: IActivityInsightsDelegate;
    public readonly maxItemsPerCard: number;

    @observable public insightCards: ActivityInsightCard[];

    constructor(activity: IAccountActivityEntry, delegate: IActivityInsightsDelegate) {
        makeObservable(this);
        this.maxItemsPerCard = 10;
        this.delegate = delegate;
        this.activity = activity;
    }

    @init()
    protected initialize() {
        this.insightCards = observable.array(this._createInsightCards());
    }

    public clearInsightsData() {
        this.insightCards.forEach((card) => card.clearData());
    }

    public async reloadInsights(useForce?: boolean) {
        await Promise.all(
            this.insightCards.map(
                (insightCard) => insightCard.didLoad || insightCard.viewable
                    ? this.loadInsightCard(insightCard, useForce)
                    : Promise.resolve()
            )
        );
    }

    public async loadInsightCard(insightCard: ActivityInsightCard, useForce?: boolean): Promise<void> {
        const field = this.delegate.selectedSummaryField.field;
        const fieldData = insightCard.getOrCreateFieldData(field);

        if (fieldData.isLoading || (fieldData.didLoad && !useForce)) {
            return;
        }

        fieldData.isLoading = true;
        const params = this.reportingManager.createActivityReportParams(this.activity, true);

        params.fields.push(field);
        params.groups.push(insightCard.group);

        const response = await this.reportingManager.addRequestToQueue(params);

        const metadata = this.reportingManager.getFieldMetadataByVertical(field, this.activity.verticalType);

        const data = take(response.sort((entryA, entryB) => entryB.fields[field] - entryA.fields[field]).map<IActivityInsightCardEntry>((entry) => {
            return {
                label: entry.groups[insightCard.group],
                value: metadata?.formatFn(entry.fields[field]) ?? entry.fields[field],
                change: isDefined(entry.fieldsCompared?.[field]) ? Math.round(entry.fields[field] / entry.fieldsCompared[field] * 100 - 100) : null
            };
        }), this.maxItemsPerCard);

        fieldData.isLoading = false;
        fieldData.didLoad = true;

        insightCard.setFieldData(field, data);
    }

    private _createInsightCards(): ActivityInsightCard[] {
        return DEFAULT_INSIGHTS.map<ActivityInsightCard>(({group, title}) => {
            return this._createActivityInsightCard(group, title);
        });
    }
}