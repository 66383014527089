import * as React from 'react';
import {Route, Routes, unstable_HistoryRouter as Router} from 'react-router-dom';
import Activity from '../../activity/view';
import App from '../../app/view';
import Home from '../../home/view';
import useMainMenuManager from '../../main-menu/hooks/useMainMenuManager';
import useRouterService from '../../router/hooks/useRouterService';
import Login from '../../session/view';

function Loading() {
    return (
        <div style={{padding: 10}}>Loading...</div>
    );
}

function Root(): JSX.Element {
    const routerService = useRouterService();
    const mainMenuManager = useMainMenuManager();

    React.useEffect(() => {
        function onHistoryChange() {
            mainMenuManager.toggleMenu(false);
        }

        return routerService.history.listen(onHistoryChange);
    }, []);

    return (
        <React.Suspense fallback={<Loading />}>
            <Router history={routerService.history}>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<App />}>
                        <Route index element={<Home />} />
                        <Route path="home" element={<Home />} />
                        <Route path="/activity/:activityId" element={<Activity />} />
                    </Route>
                </Routes>
            </Router>
        </React.Suspense>
    );
}

export default Root;