"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.injectParam = void 0;
var metadataHandlers_1 = require("../metadataHandlers");
var ARGS_REGEXP = /\(\s*([^)]+?)\s*\)/;
function injectParam(dependencyNameOrType) {
    return function (targetPrototype, methodName, index) {
        var args = ARGS_REGEXP.exec(targetPrototype[methodName].toString());
        if (args[1]) {
            var params = args[1].replace(/ /g, '').split(',');
            var dependency = params[index];
            metadataHandlers_1.default.pushMetadata(targetPrototype, "paramDependencies", {
                methodName: methodName,
                index: index,
                label: dependency,
                value: dependencyNameOrType || dependency
            });
        }
    };
}
exports.injectParam = injectParam;
