"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactPlugin = void 0;
var React = require("react");
var createConfig_1 = require("./createConfig");
var InjexProvider_1 = require("./InjexProvider");
var ReactPlugin = /** @class */ (function () {
    function ReactPlugin(config) {
        this._render = this._render.bind(this);
        this.config = createConfig_1.default(config);
    }
    ReactPlugin.prototype.apply = function (container) {
        this.container = container;
        container.addObject(this._render, "renderInjexProvider");
    };
    ReactPlugin.prototype._render = function (children, rootElement) {
        var root = rootElement;
        if (!root) {
            var rootElementOrSelector = this.config.rootElementOrSelector;
            if (typeof rootElementOrSelector === "string") {
                root = document.querySelector(rootElementOrSelector);
            }
            else {
                root = rootElementOrSelector;
            }
        }
        var provider = (React.createElement(InjexProvider_1.default, { container: this.container }, children));
        if (root) {
            this.config.render(provider, root);
        }
        else {
            this.config.render(provider);
        }
    };
    return ReactPlugin;
}());
exports.ReactPlugin = ReactPlugin;
